import React from 'react'

import {
  EuiSpacer,
  EuiTitle,
  EuiText,
  EuiButton
} from '@elastic/eui'

import FloatingGallery from '../../../../../../components/Software/Windows/InstarVision/Windows_Phone/instarvision-wp-ddns-floating-gallery'
import DDNSTable from '../../../../../../components/Software/Windows/InstarVision/Windows_Phone/instarvision-wp-ddns-table'

import NavButtons from '../../../../../../components/Software/Windows/InstarVision/Windows_Phone/NavButtons'

import SEOHelmet from '../../../../../../components/Layout/SEOHelmet'
import BreadCrumbs from '../../../../../../components/Layout/BreadCrumbs'


const seodata = {
  title: 'DDNS Menu :: InstarVision for Windows Phone',
  description: 'The DDNS Access gives you full access to all your cameras functions. As well as allowing you to access its web user interface through your browser.',
  image: '/images/Search/P_SearchThumb_InstarVision_WP.png',
  twitter: '/images/Search/P_SearchThumb_InstarVision_WP.webp',
  locationEN: '/en/Software/Windows/InstarVision/Windows_Phone/DDNS/',
  locationDE: '/de/Software/Windows/InstarVision/Windows_Phone/DDNS/',
  locationFR: '/fr/Software/Windows/InstarVision/Windows_Phone/DDNS/'
}

function InstarVisionDDNS(props) {
  return (
    <React.Fragment>

      <SEOHelmet
        title={seodata.title}
        description={seodata.description}
        image={seodata.image}
        twitter={seodata.twitter}
        location={props.location} />

      <BreadCrumbs
    locationBC={props.location}
          locationEN={seodata.locationEN}
          locationDE={seodata.locationDE}
          locationFR={seodata.locationFR}
          crumbLabel="DDNS" />
          
      <NavButtons />
    
      <EuiTitle size="l">
        <h1>InstarVision Surveillance Center</h1>
      </EuiTitle>
      <EuiTitle size="m">
        <h2>Windows Phone Software</h2>
      </EuiTitle>
      <EuiTitle size="s">
        <h4>Add a Camera: <em>DDNS Access</em></h4>
      </EuiTitle>
      <EuiSpacer size="l" />
      <EuiText size="s">
        <p><em>Are you missing Audio with your 1080p Model?</em>
          <EuiButton href="https://wiki.instar.com/en//Software/Mobile_Access_Over_HTTPS/" fill style={{float: 'right', color: 'white!important'}}>
            Audio & HTTPS
          </EuiButton>
        </p>
      </EuiText>
      <EuiSpacer size="xl" />
      <EuiText>
        <p>After you have installed the newest version please start the App and swipe to the right to enter the Cameras tab. The <strong>+</strong> symbol in the upper right lets you choose among three ways to add your IP camera.</p>
      </EuiText>
      <EuiSpacer size="l" />
      <FloatingGallery />
      <EuiSpacer size="l" />
      <EuiTitle size="m">
        <h3>Add an INSTAR DDNS IP Camera</h3>
      </EuiTitle>
      <EuiSpacer size="l" />
      <DDNSTable />
    </React.Fragment>
  );
}

export default InstarVisionDDNS