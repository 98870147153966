import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import WinPhone01 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone//WP_App1.webp'
import WinPhone02 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/Wizard2.webp'
import WinPhone03 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/Wizard3.webp'
import WinPhone04 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/Wizard4.webp'
import WinPhone05 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/Wizard5.webp'
import WinPhone06 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/Wizard6.webp'
import WinPhone07 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/Wizard7.webp'
import WinPhone08 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/Wizard8.webp'
import WinPhone09 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/Wizard9.webp'
import WinPhone10 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/Wizard10.webp'
import WinPhone11 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/Wizard11.webp'
import WinPhone12 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/Wizard11_Copy.webp'

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Go to the Multi View or Cameras menu and click the + button in the top right corner to add your camera."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Select New IP Camera to add a new IP camera."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Either scan the QR Code from the web interface of your camera or select the camera model manually."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Select the manufacturer of your camera."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone05} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Select your camera model. Then click on Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone06} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Enter a name for your camera and click Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone07} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Enter the DDNS address of your camera or the local IP address and click Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone08} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Enter the LAN (HTTP) port of your camera and click Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone09} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Enter your camera login username and click Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone10} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Enter your camera login password and click Next to continue."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone11} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="You can click the green + to add a local IP address and the local port of your camera."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone12} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera using its DDNS address"
          subtitle="Now you can check your settings and click Save to add your camera."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function DDNSGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}


export default DDNSGallery